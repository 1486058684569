import { Controller } from 'stimulus';

const NAV_CLASS = 'header__nav';

export default class extends Controller {
  static targets = ['menuButton', 'navWrapper'];

  get mainListElement() {
    return this.navWrapperTargets[0].children[0];
  }

  toggleMenu() {
    this.menuButtonTargets[0].classList.toggle(`${NAV_CLASS}-burger-menu--active`);
    this.navWrapperTargets[0].classList.toggle(`${NAV_CLASS}-list-wrapper--active`);
  }
}
