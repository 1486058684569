import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['accordion']

  toggleExpanded() {
    const accordion = this.accordionTargets[0];
    accordion.classList.toggle('accordion--is-expanded');
  }
}
