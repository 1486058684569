/* eslint-disable class-methods-use-this */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  get button() {
    return this.buttonTargets[0];
  }

  // eslint-disable-next-line class-methods-use-this
  connect() {
    window.onscroll = () => {
      this.toggleTopButtonDisplay();
    };
  }

  toggleTopButtonDisplay() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.button.classList.add('scroll-top-button--visible');
    } else {
      this.button.classList.remove('scroll-top-button--visible');
    }
  }

  scrollTop() {
    document.body.scrollTop = 0;
    document.body.scrollIntoView({ behavior: 'smooth' });
  }
}
